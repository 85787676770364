<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl mt-6">
        <v-card-text class="px-0 py-0">
          <v-data-table
            :headers="headers"
            :items="tableData"
            mobile-breakpoint="0"
            fixed-header
            class="table"
            :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>Vendor Evaluation</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <!-- -->
                <v-btn
                  v-show="
                    currentPage.permissions.includes(PERMISSIONS_ENUM.CREATE)
                  "
                  color="primary"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  class="
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                  "
                  @click="gotoCreate"
                >
                  New Vendor Evaluation
                </v-btn>
              </v-toolbar>
            </template>
            <!--    -->
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                @click="gotoDetails(item)"
                icon
                elevation="0"
                :ripple="false"
                height="28"
                min-width="36"
                width="36"
                class="ma-2"
                color="green"
                >Details
              </v-btn>

              <!-- <v-menu v-show="currentPage.actions_allowed.length > 0">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    elevation="0"
                    :ripple="false"
                    height="28"
                    min-width="36"
                    width="36"
                    class="ml-5 mx-1"
                  >
                    Actions
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(itm, i) in currentPage.actions_allowed"
                    :key="i"
                  >
                    <v-btn
                      icon
                      elevation="0"
                      :ripple="false"
                      height="28"
                      min-width="36"
                      width="80px"
                      class="my-1"
                      @click="allowedAction(itm, item.id)"
                    >
                      {{ itm }}
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu> -->
            </template>
            <template v-slot:[`item.created_date`]="{ item }">
              <span>
                {{ formatDate(item.created_date) }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import api from "../api";
import { PERMISSIONS_ENUM } from "../../../../enum/permission";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      PERMISSIONS_ENUM: PERMISSIONS_ENUM,
      ref: "",
      supplier_name: "",
      po_number: "",
      bill_no: "",
      vehicle_no: "",
      editedId: -1,
      bags: 0,
      overlay: false,
      boolTrue: true,
      ans: [],
      all_questions: [],
      questions: [],

      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
      dialog: false,
      tableData: [],
      headers: [
        {
          text: "REF",
          value: "ref",
          class: "text-secondary font-weight-bolder opacity-10",
          width: 200,
          align: "left",

          sortable: false,
        },
        {
          text: "Created Date",
          value: "created_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 200,
          sortable: false,
        },
        {
          text: "Office",
          value: "office",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 200,
          sortable: false,
        },
        {
          text: "Manufacturing site",
          value: "manufacturing_site",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 200,
          sortable: false,
        },
        {
          text: "product name",
          value: "product_name_supplied",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 200,
          sortable: false,
        },
        {
          text: "product code",
          value: "product_code",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 200,
          sortable: false,
        },
        {
          text: "tech phone",
          value: "tech_phone",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 200,
          sortable: false,
        },

        {
          text: "sale phone",
          value: "sale_phone",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 200,
          sortable: false,
        },
        {
          value: "actions",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "left",
          width: 250,
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      this.overlay = true;
      this.tableData = await api.getData();
      this.all_questions = await api.getQuestion();
      this.overlay = false;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    async editItem(item) {
      this.overlay = true;
      this.dialog = true;
      try {
        this.editedId = item.id;
        console.log(this.editedId);
        let result = await api.getById(item.id);
        console.log("result", result);
        this.bill_no = result.bill_no;
        this.created_date = new Date(result.created_date);
        this.supplier_name = result.name_of_supplier;
        this.bags = result.number_of_bags;
        this.po_number = result.po_number;
        this.ref = result.ref;
        this.vehicle_no = result.vehicle_no;
        this.questions = this.all_questions;
        this.questions.forEach((el) => {
          let i = result.irmc_child.findIndex(
            (q) => q.irmc_question.id == el.id
          );

          if (i > -1) {
            this.ans[i] = result.irmc_child[i].qc_status + "";
          }
        });
        // console.log(this.ans);
      } catch (error) {}

      this.overlay = false;
    },
    async deleteItem(item) {
      this.overlay = true;
      try {
        api.deteleMaster(item.id);
        this.tableData.splice(
          this.tableData.findIndex((el) => (el.id = item.id)),
          1
        );
        this.overlay = false;
        this.showSuccessAlert("Data Deleted");
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async allowedAction(item, id) {
      this.overlay = true;
      try {
        api.allowedAction({
          id: id,
          action: item,
        });

        this.overlay = false;
        this.showSuccessAlert("State updated");
      } catch (error) {
        this.showErrorAlert(error);
      } finally {
        this.initialize();
      }
    },
    async save() {
      let array = [];
      if (this.ans.flat().length == this.questions.length) {
        this.overlay = true;
        this.questions.forEach((el, i) => {
          let status = false;
          if (this.ans[i] == "false") {
            status = false;
          } else {
            status = true;
          }
          let d = {
            question: el,
            qc_status: status,
          };
          array.push(d);
        });
        let req = {
          ref: this.ref,
          created_date: new Date(this.date),
          name_of_supplier: this.supplier_name,
          po_number: this.po_number,
          bill_no: this.bill_no,
          vehicle_no: this.vehicle_no,
          number_of_bags: this.bags,
          irmc_child: array,
        };
        console.log(req);
        try {
          await api.saveData(req, this.editedId);
          this.overlay = false;
          this.showSuccessAlert("Data Saved");
          this.cancel();
        } catch (error) {
          this.showErrorAlert(error);
        }
      } else {
        this.showWarningAlert("Please Answer All Question");
      }
    },
    cancel() {
      this.editedId = -1;
      this.questions = [];
      this.ans = [];
      this.dialog = false;
      this.initialize();
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 3000,
        icon: "error",
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
    gotoCreate() {
      this.$router.push({ name: "New Vendor Evaluation" });
    },
    gotoDetails(item) {
      console.log(item);
      console.log("this.currentPage", this.currentPage);
      this.$router.push({
        name: "Vendor Evaluation Details",
        params: { id: item.id },
        query: {
          currentPage: this.currentPage,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      currentPage: "getCurrentPage",
    }),
  },
};
</script>

<style></style>
